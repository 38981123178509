import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` component is the engine that builds an animation and calculates each frame's state in real time.`}</p>
    <p>{`It manages an `}<inlineCode parentName="p">{`Animation`}</inlineCode>{` instance generated with `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{` and automatically queries frames based on its given time value.
`}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` may be configured to periodically update the time value via `}<inlineCode parentName="p">{`setInterval`}</inlineCode>{`, or the time value may be controlled elsewhere.`}</p>
    <h2 {...{
      "id": "import"
    }}>{`Import`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Timeline } from "react-ensemble";
`}</code></pre>
    <h2 {...{
      "id": "interface"
    }}>{`Interface`}</h2>
    <p><inlineCode parentName="p">{`Timeline`}</inlineCode>{` is a React component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const Timeline: <State extends object = any>(
  props: React.PropsWithChildren<TimelineProps<State>>
) => null;
`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`TimelineProps<State extends object = any>`}</inlineCode></li>
      <li parentName="ul">{`The optional generic type parameter `}<inlineCode parentName="li">{`State`}</inlineCode>{` refers to the structure of the animation's state. Must be an object.`}</li>
    </ul>
    <p>{`Properties for the `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface TimelineProps<State extends object = any> {
  track: TrackRegion<State>[];
  defaultState: State;
  value: number;
  playing: boolean;
  playbackSpeed?: number;
  interval?: number;
  endBehavior?: TimelineEndBehavior;
  avoidReload?: boolean;
  easing?: EasingFunction;
  interp?: InterpolationFunction;
  resolver?: TrackLayerResolver<State>;
  onTick?: (event: TickEvent) => void;
  onUpdate?: (event: UpdateEvent<State>) => void;
  onEnded?: () => void;
  onLoad?: (event: LoadEvent<State>) => void;
}
`}</code></pre>
    <h3 {...{
      "id": "track"
    }}>{`track`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<a parentName="li" {...{
          "href": "/docs/api/types#trackregion"
        }}><inlineCode parentName="a">{`TrackRegion<State>[]`}</inlineCode></a></li>
      <li parentName="ul"><strong parentName="li">{`Required`}</strong></li>
    </ul>
    <p>{`The array of regions that make up the animation.`}</p>
    <p><inlineCode parentName="p">{`track`}</inlineCode>{` is passed into `}<inlineCode parentName="p">{`TrackUtils.gen()`}</inlineCode>{` immediately after `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` mounts, calculating the `}<inlineCode parentName="p">{`Animation`}</inlineCode>{`.
After `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` initializes, it will not re-calculate the animation if `}<inlineCode parentName="p">{`track`}</inlineCode>{` changes when `}<inlineCode parentName="p">{`avoidReload = true`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "defaultstate"
    }}>{`defaultState`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`State`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Required`}</strong></li>
    </ul>
    <p>{`The animation's default state. Must be an object.`}</p>
    <p><inlineCode parentName="p">{`defaultState`}</inlineCode>{` is passed into `}<inlineCode parentName="p">{`TrackUtils.gen()`}</inlineCode>{` immediately after `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` mounts, calculating the `}<inlineCode parentName="p">{`Animation`}</inlineCode>{`.
After `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` initializes, it will not re-calculate the animation if `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{` changes when `}<inlineCode parentName="p">{`avoidReload = true`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "value"
    }}>{`value`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`number`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Required`}</strong></li>
    </ul>
    <p>{`The current frame of the animation, in milliseconds.`}</p>
    <p>{`Changing this prop triggers a refresh within `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` that queries the animation and returns the current frame state via `}<inlineCode parentName="p">{`onUpdate`}</inlineCode>{` asynchronously.`}</p>
    <h3 {...{
      "id": "playing"
    }}>{`playing`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`boolean`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Required`}</strong></li>
    </ul>
    <p>{`Whether or not the animation is playing.`}</p>
    <p>{`While true, the interval within `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` will run (as specified by the `}<inlineCode parentName="p">{`interval`}</inlineCode>{` prop) and trigger `}<inlineCode parentName="p">{`onTick`}</inlineCode>{` callbacks.`}</p>
    <h3 {...{
      "id": "playbackspeed"
    }}>{`playbackSpeed`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`number`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`1`}</inlineCode></li>
    </ul>
    <p>{`The timeline's playback speed multiplier.`}</p>
    <p><inlineCode parentName="p">{`1`}</inlineCode>{` plays the animation at regular speed, `}<inlineCode parentName="p">{`2`}</inlineCode>{` plays at double speed, `}<inlineCode parentName="p">{`0.5`}</inlineCode>{` plays at half speed, etc.
Supply a negative number to play in reverse. For example, `}<inlineCode parentName="p">{`-2`}</inlineCode>{` plays backwards at double speed.`}</p>
    <h3 {...{
      "id": "interval"
    }}>{`interval`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`number`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`10`}</inlineCode></li>
    </ul>
    <p>{`The number of milliseconds the interval within `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` will be set to, roughly corresponding to how often `}<inlineCode parentName="p">{`onTick`}</inlineCode>{` callbacks will be triggered.`}</p>
    <p>{`This property implies a performance versus quality tradeoff: larger intervals will refresh the frame less frequently,
causing choppier animations but using less resources, whereas smaller intervals will have smaller gaps between refreshes but a greater performance cost.`}</p>
    <p><strong parentName="p">{`This property is an approximation`}</strong>{`, and will not match exactly with the values returned by `}<inlineCode parentName="p">{`onTick`}</inlineCode>{`.
There are unavoidable (but minor) delays caused by querying the animation object and React renders, so expect less precision at the millisecond level.`}</p>
    <h3 {...{
      "id": "endbehavior"
    }}>{`endBehavior`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<a parentName="li" {...{
          "href": "/docs/api/types#timelineendbehavior"
        }}><inlineCode parentName="a">{`TimelineEndBehavior`}</inlineCode></a></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`"stop"`}</inlineCode></li>
    </ul>
    <p>{`Describes how the engine will calculate frame states for time values greater than the length of the animation.`}</p>
    <h3 {...{
      "id": "avoidreload"
    }}>{`avoidReload`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`boolean`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`true`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Since: v1.1.0`}</em></li>
    </ul>
    <p>{`Whether or not `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` will rebuild the animation when references to `}<inlineCode parentName="p">{`track`}</inlineCode>{` or `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{` change.`}</p>
    <p>{`This property is `}<inlineCode parentName="p">{`true`}</inlineCode>{` by default, meaning the animation will rebuild only when `}<inlineCode parentName="p">{`endBehavior`}</inlineCode>{`, `}<inlineCode parentName="p">{`easing`}</inlineCode>{`, `}<inlineCode parentName="p">{`interp`}</inlineCode>{`, or `}<inlineCode parentName="p">{`resolver`}</inlineCode>{` change.
Changing `}<inlineCode parentName="p">{`track`}</inlineCode>{` or `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{` will have no effect post-initialization.`}</p>
    <p>{`Setting `}<inlineCode parentName="p">{`avoidReload`}</inlineCode>{` to `}<inlineCode parentName="p">{`false`}</inlineCode>{` can be helpful if you want an animation to change dynamically after the `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` has already initialized.`}</p>
    <p><strong parentName="p">{`Disabling this prop can have negative performance effects`}</strong>{` for your animation if `}<inlineCode parentName="p">{`track`}</inlineCode>{` or `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{` are redefined on every render of your parent component.
Be sure to define `}<inlineCode parentName="p">{`track`}</inlineCode>{` and `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{` outside your render function or properly memoize them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Instead of this:
() => {
  return (
    <Timeline
      avoidReload={false}
      track={[{ duration: 1000 /* etc */ }]}
      defaultState={{ x: 0 }}
      {...props}
    />
  );
};

// Do this:
() => {
  const track = React.useMemo(() => [{ duration: 1000 /* etc */ }], []);
  const defaultState = React.useMemo(() => ({ x: 0 }), []);

  return (
    <Timeline
      avoidReload={false}
      track={track}
      defaultState={defaultState}
      {...props}
    />
  );
};
`}</code></pre>
    <h3 {...{
      "id": "easing"
    }}>{`easing`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<a parentName="li" {...{
          "href": "/docs/api/types#easingfunction"
        }}><inlineCode parentName="a">{`EasingFunction`}</inlineCode></a></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`undefined`}</inlineCode></li>
    </ul>
    <p>{`Sets the animation's default `}<a parentName="p" {...{
        "href": "/docs/guides/easing-and-interpolating#easing"
      }}>{`easing function`}</a>{`.`}</p>
    <p>{`If not defined, `}<a parentName="p" {...{
        "href": "/docs/api/trackutils#gen"
      }}><inlineCode parentName="a">{`TrackUtils.gen`}</inlineCode></a>{` will use its own default easing function.`}</p>
    <h3 {...{
      "id": "interp"
    }}>{`interp`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<a parentName="li" {...{
          "href": "/docs/api/types#interpolationfunction"
        }}><inlineCode parentName="a">{`InterpolationFunction`}</inlineCode></a></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`undefined`}</inlineCode></li>
    </ul>
    <p>{`Sets the animation's default `}<a parentName="p" {...{
        "href": "/docs/guides/easing-and-interpolating#interpolating"
      }}>{`interpolation function`}</a>{`.`}</p>
    <p>{`If not defined, `}<a parentName="p" {...{
        "href": "/docs/api/trackutils#gen"
      }}><inlineCode parentName="a">{`TrackUtils.gen`}</inlineCode></a>{` will use its own default interpolation function.`}</p>
    <h3 {...{
      "id": "resolver"
    }}>{`resolver`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<a parentName="li" {...{
          "href": "/docs/api/types#tracklayerresolver"
        }}><inlineCode parentName="a">{`TrackLayerResolver<State>`}</inlineCode></a></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`undefined`}</inlineCode></li>
    </ul>
    <p>{`Sets the animation's default `}<a parentName="p" {...{
        "href": "/docs/guides/layers#layer-resolution"
      }}>{`layer resolver`}</a>{`.`}</p>
    <p>{`If not defined, `}<a parentName="p" {...{
        "href": "/docs/api/trackutils#gen"
      }}><inlineCode parentName="a">{`TrackUtils.gen`}</inlineCode></a>{` will use its own default layer resolver.`}</p>
    <h3 {...{
      "id": "ontick"
    }}>{`onTick`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`(event: TickEvent) => void`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`() => {}`}</inlineCode></li>
    </ul>
    <p>{`If `}<inlineCode parentName="p">{`playing`}</inlineCode>{` is true, this callback will fire approximately every `}<inlineCode parentName="p">{`interval`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`event.value`}</inlineCode>{` will be equal to the time value when the event was created.
See `}<a parentName="p" {...{
        "href": "/docs/api/types#tickevent"
      }}><inlineCode parentName="a">{`TickEvent`}</inlineCode></a>{`.`}</p>
    <p>{`Useful if a parent component is storing the animation's time value as state:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`onTick={({ value }) => setTick(value)}
`}</code></pre>
    <h3 {...{
      "id": "onupdate"
    }}>{`onUpdate`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`(event: UpdateEvent<State>) => void`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`() => {}`}</inlineCode></li>
    </ul>
    <p>{`Will fire with the current frame state according to `}<inlineCode parentName="p">{`value`}</inlineCode>{` whenever `}<inlineCode parentName="p">{`value`}</inlineCode>{` changes.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/docs/api/types#updateevent"
      }}><inlineCode parentName="a">{`UpdateEvent`}</inlineCode></a>{`.`}</p>
    <p>{`Useful if a parent component is storing the animation's frame state:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`onUpdate={({ state }) => setAnimState(state)}
`}</code></pre>
    <h3 {...{
      "id": "onended"
    }}>{`onEnded`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`() => void`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`() => {}`}</inlineCode></li>
    </ul>
    <p>{`Will fire when `}<inlineCode parentName="p">{`value`}</inlineCode>{` is greater than or equal to the length of the track or when `}<inlineCode parentName="p">{`value`}</inlineCode>{` is less than zero.`}</p>
    <p>{`May trigger more than once.`}</p>
    <h3 {...{
      "id": "onload"
    }}>{`onLoad`}</h3>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`(event: LoadEvent<State>) => void`}</inlineCode></li>
      <li parentName="ul">{`Default: `}<inlineCode parentName="li">{`() => {}`}</inlineCode></li>
    </ul>
    <p>{`Will fire when `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` initializes, returning the `}<inlineCode parentName="p">{`Animation`}</inlineCode>{` calculated by `}<inlineCode parentName="p">{`TrackUtils.gen()`}</inlineCode>{`.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/docs/api/types#loadevent"
      }}><inlineCode parentName="a">{`LoadEvent`}</inlineCode></a>{`.`}</p>
    <p><inlineCode parentName="p">{`Timeline`}</inlineCode>{` will initialize when it is mounted and whenever the following props change:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`endBehavior`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`easing`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`interp`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`resolver`}</inlineCode></li>
    </ul>
    <p>{`If `}<inlineCode parentName="p">{`avoidReload = false`}</inlineCode>{`, `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` will also initialize whenever these props change:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`track`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`defaultState`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      